/*es-lint disabled*/
module.exports = {
  install(Vue) {
    (Vue.initBaseDate = function (baseDate) {
      if (Vue.isValidDateString(baseDate)) {
        return Vue.dateToDateString(baseDate);
      } else {
        return Vue.dateToDateString(new Date());
      }
    }),
      (Vue.makeBalanceOHLCList = function (groupByBankAccountList) {
        if (groupByBankAccountList.length === 0) {
          return [];
        } else {
          const result = [];
          groupByBankAccountList.forEach((bank) => {
            bank.values.forEach((account) => {
              const ohlcList = Vue.getBankAccountBalanceOHLCList(
                bank.key,
                account.key,
                account.values
              );
              ohlcList.list.forEach((data) => {
                result.push(data);
              });
            });
          });
          return result;
        }
      }),
      (Vue.getBankAccountBalanceOHLCList = function (
        bankName,
        accountName,
        groupByBankAccountList
      ) {
        if (groupByBankAccountList.length === 0) {
          return {
            minDate: "9999-12-31",
            maxDate: "",
            bank: bankName,
            account: accountName,
            list: [],
          };
        } else {
          let minDate = "9999-12-31",
            maxDate = "";
          const result = {
            bank: bankName,
            accountName: accountName,
            list: [],
          };
          groupByBankAccountList.sort(function (a, b) {
            return a.tran_date > b.tran_date
              ? 1
              : a.tran_date < b.tran_date
              ? -1
              : 0;
          });
          let nowDate = Vue.dateToDateString(
            groupByBankAccountList[0].tran_date
          );
          let open =
              groupByBankAccountList[0].inout_type === 1
                ? groupByBankAccountList[0].after_balance_amt -
                  groupByBankAccountList[0].tran_amt
                : groupByBankAccountList[0].after_balance_amt +
                  groupByBankAccountList[0].tran_amt,
            high = Number.MIN_SAFE_INTEGER,
            low = Number.MAX_SAFE_INTEGER,
            close = groupByBankAccountList[0].after_balance_amt;
          groupByBankAccountList.forEach((element) => {
            // console.log(element);
            if (minDate > Vue.dateToDateString(element.tran_date)) {
              minDate = Vue.dateToDateString(element.tran_date);
            }
            if (maxDate < Vue.dateToDateString(element.tran_date)) {
              maxDate = Vue.dateToDateString(element.tran_date);
            }
            if (nowDate < Vue.dateToDateString(element.tran_date)) {
              result.list.push({
                date: nowDate,
                open: open,
                high: high,
                low: low,
                close: close,
              });
              nowDate = Vue.getAfterDayString(nowDate, 1);
              while (nowDate !== Vue.dateToDateString(element.tran_date)) {
                result.list.push({
                  date: nowDate,
                  open: close,
                  high: close,
                  low: close,
                  close: close,
                });
                nowDate = Vue.getAfterDayString(nowDate, 1);
              }
              open = element.after_balance_amt;
              high = element.after_balance_amt;
              low = element.after_balance_amt;
              close = element.after_balance_amt;
            } else {
              if (element.after_balance_amt > high)
                high = element.after_balance_amt;
              if (element.after_balance_amt < low)
                low = element.after_balance_amt;
              close = element.after_balance_amt;
            }
          });
          result.list.push({
            date: nowDate,
            open: open,
            high: high,
            low: low,
            close: close,
          });
          result.minDate = minDate;
          result.maxDate = maxDate;
          return result;
        }
      }),
      (Vue.getAfterDayString = function (baseDate, count) {
        if (count === 0) {
          return Vue.dateToDateString(baseDate);
        }
        while (count !== 0) {
          let today = new Date(Vue.dateToDateString(baseDate));
          today.setDate(today.getDate() + 1);
          const year = today.getFullYear();
          let month = (today.getMonth() + 1).toString();
          let day = today.getDate().toString();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          baseDate = [year, month, day].join("-");
          count -= 1;
        }
        return Vue.dateToDateString(baseDate);
      }),
      (Vue.getBefore6MonthString = function (baseDate) {
        if (typeof baseDate === "string") {
          baseDate = baseDate.replace(/[.-]/gi, "/");
        }
        let today = new Date(baseDate);
        today.setMonth(today.getMonth() - 5);
        return Vue.dateToYearMonthString(today) + "-01";
      }),
      (Vue.getBeforeMonthString = function (baseDate, count) {
        if (count === 0) {
          return Vue.dateToDateString(baseDate);
        }
        while (count !== 0) {
          if (typeof baseDate === "string") {
            baseDate = baseDate.replace(/[.-]/gi, "/");
          }
          let today = new Date(baseDate);
          today.setMonth(today.getMonth() - 1);

          baseDate = Vue.dateToDateString(today);
          count -= 1;
        }
        return Vue.dateToDateString(baseDate);
      }),
      (Vue.getAfterMonthString = function (baseDate, count) {
        if (count === 0) {
          return Vue.dateToDateString(baseDate);
        }
        while (count !== 0) {
          if (typeof baseDate === "string") {
            baseDate = baseDate.replace(/[.-]/gi, "/");
          }
          let today = new Date(baseDate);
          today.setMonth(today.getMonth() + 1);

          baseDate = Vue.dateToDateString(today);
          count -= 1;
        }
        return Vue.dateToDateString(baseDate);
      }),
      (Vue.getMonthFirstDayString = function (date) {
        if (typeof date === "string") {
          date = date.replace(/[.-]/gi, "/");
        }
        let today = new Date(date);
        const year = today.getFullYear();
        let month = (today.getMonth() + 1).toString();

        if (month.length < 2) month = "0" + month;
        const day = "01";
        return [year, month, day].join("-");
      }),
      (Vue.diffMonthString = function (date1, date2) {
        let d1 = Vue.dateToDateString(date1);
        let d2 = Vue.dateToDateString(date2);
        if (d1 > d2) {
          return "-";
        } else if (d1 === d2) {
          return "1M";
        } else {
          let count = 0;
          while (d2 >= d1) {
            d2 = Vue.getBeforeMonthString(d2, 1);
            count += 1;
            if (d1 >= d2) {
              break;
            }
          }
          return String(count) + "M";
        }
        //
        // let months;
        // const d1 = new Date(date1);
        // const d2 = new Date(date2);
        //
        // months = (d2.getFullYear() - d1.getFullYear()) * 12;
        // months -= d1.getMonth();
        // months += d2.getMonth();
        // return months <= 0 ? "1M" : String(months + 1) + "M";
      }),
      (Vue.dateToMonthString = function (date) {
        if (!date) {
          return "-";
        }
        if (typeof date === "string") {
          date = date.replace(/[.-]/gi, "/");
        }
        const d = new Date(date);
        let month = (d.getMonth() + 1).toString();
        return month + "월";
      }),
      (Vue.dateToYearMonthString = function (date, splitter = "-") {
        if (!date) {
          return "-";
        }
        if (typeof date === "string") {
          date = date.replace(/[.-]/gi, "/");
        }
        const d = new Date(date);
        const year = d.getFullYear();
        let month = (d.getMonth() + 1).toString();

        if (month.length < 2) month = "0" + month;

        return [year, month].join(splitter);
      }),
      (Vue.dateToDateString = function (date, splitter = "-") {
        if (!date) {
          return "-";
        }
        if (typeof date === "string") {
          date = date.replace(/[.-]/gi, "/");
        }
        const d = new Date(date);
        const year = d.getFullYear();
        let month = (d.getMonth() + 1).toString();
        let day = d.getDate().toString();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join(splitter);
      }),
      (Vue.isValidDateString = function (str) {
        // STRING FORMAT yyyy-mm-dd
        if (str === "" || !str) {
          return false;
        }
        const m = str.match(/(\d{4})-(\d{2})-(\d{2})/);

        if (m === null || typeof m !== "object") {
          return false;
        }

        if (typeof m !== "object" && m !== null && m.size !== 3) {
          return false;
        }

        let ret = true;
        let thisYear = new Date().getFullYear();
        let minYear = 1999;

        if (m[1].length < 4 || m[1] < minYear || m[1] > thisYear) {
          ret = false;
        }
        if (m[2].length < 2 || m[2] < 1 || m[2] > 12) {
          ret = false;
        }
        if (m[3].length < 2 || m[3] < 1 || m[3] > 31) {
          ret = false;
        }

        return ret;
      }),
      (Vue.dateToDateTimeString = function (
        date,
        date_splitter = "-",
        time_splitter = ":"
      ) {
        if (!date) return "-";
        if (typeof date === "string") {
          date = date.replace(/[.-]/gi, "/");
        }
        const d = new Date(date);
        const year = d.getFullYear();
        let month = (d.getMonth() + 1).toString();
        let day = d.getDate().toString();
        let hour = d.getHours().toString();
        let minute = d.getMinutes().toString();
        let second = d.getSeconds().toString();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (hour.length < 2) hour = "0" + hour;
        if (minute.length < 2) minute = "0" + minute;
        if (second.length < 2) second = "0" + second;

        return [
          [year, month, day].join(date_splitter),
          [hour, minute, second].join(time_splitter),
        ].join(" ");
      });
  },
};
