<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <TransactionDataTable
              ref="transactionTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '전체 거래내역',
                detailData: detailDataArray,
                titleTextClass: '',
                itemsPerPage: 30,
              }"
            >
            </TransactionDataTable>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TransactionDataTable from "@/views/partials/widgets/tables/TransactionDataTable.vue";

export default {
  name: "TransactionHistory",
  components: {
    KTPortlet,
    TransactionDataTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      tabIndex: 0,
      tabIndex2: 0,
      detailDataArray: this.$store.getters.requestItem.items,
    };
  },
  mounted() {
    document.title = "전체 거래내역";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.detailDataArray = this.$store.getters.requestItem.items;
      this.$refs.transactionTable.setData(this.baseDate, this.detailDataArray);
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
